<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader :title="$t.addCargoCompany" />
    <Main>
      <a-button
        type="primary"
        style="position: absolute;top: 30px;right: 30px"
        @click="show_create_form = true"
      >
        <template #icon>
          <PlusOutlined />
        </template>
        {{ $t.addCargo }}
      </a-button>
      <a-row
        v-if="customer_shipping_companies.length === 0"
        type="flex"
        justify="center"
        :gutter="12"
        align="top"
      >
        <a-col :span="16">
          <a-button
            type="dashed"
            ghost
            block
            @click="show_create_form = true"
          >
            {{ $t.cargoCompanyNotFound }} <span style="color:#5f63f2;font-weight: bold"> {{ $t.addNow }}</span>
          </a-button>
        </a-col>
        <a-col :span="16">
          <img
            alt="example"
            src="../../../img/company-not-found.png"
            style="width: 100%;"
          >
        </a-col>
      </a-row>
      <a-row
        v-else
        type="flex"
        justify="start"
        :gutter="12"
        align="top"
      >
        <a-col
          v-for="item in customer_shipping_companies"
          :key="item.id"
          :span="6"
          style="margin-bottom: 10px"
        >
          <a-spin :spinning="edit_form_open_loadings[item.id] === true">
            <a-card
              hoverable
              @click="editCargoModalForm(item.id)"
            >
              <template #cover>
                <img
                  alt="example"
                  :src="require('@/img/' + item.code+'-logo.png')"
                >
              </template>
              <a-card-meta>
                <template #title>
                  <div class="text-center">
                    {{ item.title }}
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </a-spin>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
  <a-modal
    v-model:visible="show_create_form"
    title="Ekleme"
    :confirm-loading="create_form_submit_loading"
    :ok-text="$t.save"
    :cancel-text="$t.cancel"
    @ok="handleCreateCompany"
    @cancel="show_create_form = false"
  >
    <a-spin :spinning="create_form_open_loading">
      <a-form layout="vertical">
        <a-form-item
          :label="$t.shipping_company_title"
          name="title"
        >
          <a-input
            id="title"
            v-model:value="create_form_state.title"
            name="title"
          />
        </a-form-item>
        <a-form-item
          id="code"
          :label="$t.chooseCargoCompany"
        >
          <a-select
            id="code"
            v-model:value="create_form_state.code"
            default-value="Firma Seçiniz"
            size="large"
            class="sDash_fullwidth-select"
            @change="handleCompanyChange()"
          >
            <a-select-option
              v-for="item in shipping_company_modules"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-for="item in create_form_state.form_options"
          :id="item.code"
          :key="item.code"
          :label="item.label"
        >
          <a-select
            v-if="item.type === 'select'"
            :id="item.code"
            v-model:value="create_form_state.form_data[item.code]"
            size="large"
            class="sDash_fullwidth-select"
          >
            <a-select-option
              v-for="option in item.data.options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
          <span v-else-if="item.type==='checkbox'">
            <a-switch
              :id="item.code"
              v-model:checked="create_form_state.form_data[item.code]"
              :checked-children="$t.yes"
              :un-checked-children="$t.no"
            />
          </span>
          <a-input
            v-else
            :id="item.code"
            v-model:value="create_form_state.form_data[item.code]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
  <a-modal
    v-model:visible="show_edit_form"
    title="Düzenle"
  >
    <a-form layout="vertical">
      <a-form-item
        id="title"
        :label="$t.shipping_company_title"
      >
        <a-input
          id="title"
          v-model:value="edit_form_state.title"
        />
      </a-form-item>
      <a-form-item
        v-for="item in edit_form_state.form_options"
        :id="item.code"
        :key="item.code"
        :label="item.label"
      >
        <a-select
          v-if="item.type==='select'"
          :id="item.code"
          v-model:value="edit_form_state.form_data[item.code]"
          size="large"
          class="sDash_fullwidth-select"
        >
          <a-select-option
            v-for="option in item.data.options"
            :key="option.value"
            :value="option.value"
            :selected="option.value===item.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
        <span v-else-if="item.type==='checkbox'">
          <a-switch
            :id="item.code"
            v-model:checked="edit_form_state.form_data[item.code]"
            :checked-children="$t.yes"
            :un-checked-children="$t.no"
          />
        </span>
        <a-input
          v-else
          :id="item.code"
          v-model:value="edit_form_state.form_data[item.code]"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-popconfirm
        :title="$t.areYouSureYouWantToDelete"
        :ok-text="$t.yes"
        :cancel-text="$t.no"
        @confirm="deleteCargoInfo"
      >
        <a-button
          style="background: #ce5e5c;color: white"
          :loading="form_deleting"
        >
          {{ $t.delete }}
        </a-button>
      </a-popconfirm>
      <a-button @click="show_edit_form = false">
        {{ $t.cancel }}
      </a-button>
      <a-button
        type="primary"
        :loading="edit_form_submit_loading"
        @click="handleEditCompany"
      >
        {{ $t.save }}
      </a-button>
    </template>
  </a-modal>
</template>


<script>
import { Main } from '@/view/styled'
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  name: 'MainPage',
  components: {
    Main,
    PlusOutlined,
  },
  data() {
    return {
      page_loading: false,
      create_form_open_loading: false,
      create_form_submit_loading: false,
      form_deleting: false,
      edit_form_open_loadings: [],
      edit_form_submit_loading: false,

      shipping_company_modules: [],
      customer_shipping_companies: [],

      show_create_form: false,

      create_form_state: {
        code: '',
        title: '',
        form_options: [],
        form_data: {},
      },

      show_edit_form: false,

      edit_form_state: {
        id: '',
        title: '',
        form_options: [],
        form_data: {},
      },
    }
  },
  mounted() {
    this.loadPageData()
  },
  methods: {
    deleteCargoInfo() {
      this.form_deleting = true
      this.$restMethods.postForCargongWebApp('/delete-configured-shipping-company/' + this.edit_form_state.id).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(this.$t.shippingInformationDeleted)
          this.loadPageData()
          this.show_edit_form = false
        }
        this.form_deleting = false
      })
    },
    handleEditCompany() {
      this.edit_form_submit_loading = true
      this.$restMethods.postForCargongWebApp('/edit-configured-shipping-company-submit/' + this.edit_form_state.id, {
        title: this.edit_form_state.title,
        data: this.edit_form_state.form_data,
      }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(this.$t.shippingInformationUpdated)
          this.show_edit_form = false
          this.loadPageData()
        }
        this.edit_form_submit_loading = false
      })
    },
    editCargoModalForm(id) {
      this.edit_form_open_loadings[id] = true
      this.$restMethods.postForCargongWebApp('/edit-configured-shipping-company-open/' + id).then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          this.edit_form_state.id = data.id
          this.edit_form_state.title = data.title
          this.edit_form_state.form_options = data.form_options
          this.edit_form_state.form_data = data.form_data

          this.show_edit_form = true

        }
        this.edit_form_open_loadings[id] = false
      })
    },
    handleCreateCompany() {
      this.create_form_submit_loading = true
      this.$restMethods.postForCargongWebApp('/create-configured-shipping-company', {
        title: this.create_form_state.title,
        code: this.create_form_state.code,
        data: this.create_form_state.form_data,
      }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(this.$t.saveSuccessful)
          this.show_create_form = false
          this.create_form_state.title = ''
          this.create_form_state.code = ''
          this.create_form_state.form_data = {}
          this.loadPageData()
        }
        this.create_form_submit_loading = false
      })
    },
    handleCompanyChange() {
      this.create_form_state.form_options = []
      this.create_form_state.form_data = {}
      this.create_form_open_loading = true
      this.$restMethods.postForCargongWebApp('/get-shipping-company-form-options', {
        code: this.create_form_state.code,
      }).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.create_form_state.form_options = data.form_options
          this.create_form_state.form_data = data.form_data
        } else {
          this.create_form_state.code = ''
        }
        this.create_form_open_loading = false
      })
    },
    loadPageData() {
      this.page_loading = true
      this.$restMethods.postForCargongWebApp('/shipping-companies-settings-open').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.shipping_company_modules = data.shipping_company_modules
          this.customer_shipping_companies = data.customer_shipping_companies
        }
        this.page_loading = false
      })
    },
  },
}
</script>
